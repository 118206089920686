<template>
  <base-layout>
    <section>
      <h1>Вопросы и Ответы</h1>
      <a href="#feedback" class="button button-main">задать свой вопрос</a>
    </section>
    <section class="faq">
      <div class="collapse">
        <div
             v-for="item in this.articles" :key="item.key"
             class="collapse__item" :class="{ active: item.isVisible }">
          <div @click="toggleCollapse(item)" class="collapse__title">{{ item.header }}<a @click.prevent="function (){}" class="close" href="#"></a></div>
          <div class="collapse__body"><span v-html="item.content"></span></div>
        </div>
      </div>
    </section>
    <section id="feedback" class="feedback">
      <h2>Если ты не нашел ответ на свой вопрос, напиши нам</h2>
      <div class="form">
        <form @submit.prevent="sendFeedback" method="POST" action="#">
          <input @keydown="removeError('name')" :class="{ 'input-error': hasError('name') }"
               type="text" v-model="name" name="name" placeholder="ИМЯ">
          <input @keydown="removeError('email')" :class="{ 'input-error': hasError('email') }"
               type="email" v-model="email" name="email" placeholder="E-MAIL">
          <select @change="removeError('theme_id')" :class="{ 'input-error': hasError('theme_id') }"
               v-model="theme_id" name="theme_id">
            <option selected value="1">Общие вопросы</option>
            <option value="2">Регистрация чека</option>
            <option value="3">Призовой фонд</option>
            <option value="4">Вопросы о продукте</option>
            <option value="5">Технические вопросы</option>
            <option value="6">Другое</option>
          </select>
          <textarea @keydown="removeError('message')" :class="{ 'input-error': hasError('message') }"
                     v-model="message" name="message" id="message" cols="30" rows="10" placeholder="Сообщение"></textarea>
          <div style="margin-left: 2.5%; display:inline-block">Изображение</div>
          <label for="upload">обзор</label>
          <input @change="submitMedia" id="upload" name="upload" type="file">
          <div style="display: inline-block; margin-top: 25px;" class="captcha">
            <vue-recaptcha sitekey="6Leab68fAAAAAIJiItrjyOLcpvQcT3EeTwFyz5Ds"></vue-recaptcha>
          </div>
          <img v-show="filePreview" :src="filePreview" alt="picture" class="image-preview">
          <button style="width: 95%;" class="button button-main">Отправить</button>
        </form>
      </div>
    </section>
  </base-layout>
</template>

<script>
import BaseLayout from "@/layouts/BaseLayout"
import { VueRecaptcha } from 'vue-recaptcha';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const TIMEOUT = 1;

export default {
  name: "FAQ",
  components: {
    BaseLayout,
    VueRecaptcha,
  },

  mounted () {
    // From testing, without a brief timeout, it won't work.
    if (this.$route.hash) {
      setTimeout(() => this.scrollTo(this.$route.hash), TIMEOUT)
    }
  },

  data() {
    return {
      name: "",
      email: "",
      theme_id: 1,
      message: "",
      file_contents: null,
      filePreview: null,
      errors: {
        name: false,
        email: false,
        theme_id: false,
        message: false
      },
      articles: [
        {
          key: 1,
          header: "Кто является организатором Акции?",
          content: "Организатором Акции, то есть юридическим лицом, созданным в соответствии с законодательством Российской Федерации, является Общество с ограниченной ответственностью «Дайверсити» (фактический адрес: 115114, г. Москва, 2-й Кожевнический пер. д 12, под.13; юридический адрес: 123103, г. Москва, вн. тер. г. муниципальный округ Хорошево-Мневники, ул. Генерала Глаголева, д. 19, кв. 45, ИНН 7723633495, КПП 502401001).",
          isVisible: false
        },
        {
          key: 2,
          header: "Где проводится Акция?",
          content: "Акция проводится на территории Российской Федерации.",
          isVisible: false
        },
        {
          key: 3,
          header: "Может ли Акция закончиться досрочно?",
          content: "Организатор оставляет за собой право вносить изменения в настоящие Правила, в том числе в части изменения наименования и количества призов, с обязательным опубликованием таких изменений на сайте. Изменения вступают в силу с момента их опубликования на Сайте, если не указана иная дата в связи с внесением изменений в настоящие Правила.",
          isVisible: false
        },
        {
          key: 4,
          header: "Какая продукция участвует в Акции?",
          content: "Сокосодержащие напитки под товарными знаками «Добрый» «Супер Дринк» в упаковках объемом 0,33 л;<br>" +
              "Соки под товарными знаками «Добрый» «Супер Джус» в упаковках объемом 0,2 л.",
          isVisible: false
        },
        {
          key: 5,
          header: "Кто может принимать участие в Акции?",
          content: "Участниками могут стать физические лица, обладающие гражданской дееспособностью, достигшие возраста 18 лет на дату проведения Акции, являющиеся гражданами Российской Федерации.",
          isVisible: false
        },
        {
          key: 6,
          header: "Какие сроки проведения Акции, регистрации чеков и вручения призов?",
          content: "Срок проведения Акции: с 01 мая 2022 года по 28 февраля 2023 года.<br><br>" +
          "Срок регистрации чеков: с 01 мая 2022 года с 00 часов 00 минут 00 секунд по московскому времени по 31 декабря 2022 года до 23 часов 59 минут 59 секунд по московскому времени (включительно). Вручение призов – в срок до 28 февраля 2023 года.",
          isVisible: false
        },
        {
          key: 7,
          header: "В каких магазинах я могу купить Продукцию, участвующую в Акции?",
          content: "В любой точке продаж продукции на территории Российской Федерации.",
          isVisible: false
        },
        {
          key: 8,
          header: "Что делать, если не получается зарегистрировать чек?",
          content: "По всем вопросам, связанным с загрузкой и регистрацией чеков, а также другим вопросам, вы можете обратиться в обратную связь сайта или написать Организатору Акции по электронной почте: info@academy.dobry.ru",
          isVisible: false
        },
        {
          key: 9,
          header: "Как начисляются баллы?",
          content: "За регистрацию Продукции:<br>" +
              "1 Продукт = 1 балл<br>",
          isVisible: false
        },
        {
          key: 10,
          header: "Какие призы я могу выиграть?",
          content: "Ежемесячный розыгрыш 100 000 рублей среди всех участников, накопивших 15 баллов<br>" +
              "Обменные призы:<br>" +
              "Мерч с AR-реальностью: <br>" +
              "Шапка – 9 баллов <br>" +
              "Футболка – 6 баллов <br>" +
              "Худи – 12 баллов <br>",
    isVisible: false
        },
        {
          key: 11,
          header: "Какие сроки проверки чеков?",
          content: "Каждый чек проходит модерацию в течение 72 часов, если запрос сделан в рабочий день (понедельник-пятница)",
          isVisible: false
        },
        {
          key: 12,
          header: "Сохранять ли чеки?",
          content: "Чеки за покупку Продукции необходимо сохранять в течение всего периода проведения Акции.",
          isVisible: false
        },
        {
          key: 13,
          header: "Как я могу узнать, выиграл я или нет?",
          content: "Информация о выигранных призах отображается в вашем личном кабинете.",
          isVisible: false
        },
        {
          key: 14,
          header: "Кто оплачивает налог за призы стоимостью более 4 000 рублей?",
          content: "Все налоги за призы стоимостью более 4 000 рублей оплачивает Организатор Акции.",
          isVisible: false
        },
        {
          key: 15,
          header: "Остались вопросы?",
          content: "Читайте полные правила Акции или отправьте запрос в форме обратной связи на сайте: http://dobry-academia.ru/",
          isVisible: false
        },
      ]
    }
  },

  methods: {
    async submitMedia(event) {
      this.filePreview = URL.createObjectURL(event.target.files[0])
      // this.file_contents = await getBase64(event.target.files[0])
    },
    scrollTo: function (hashtag) {
      setTimeout(() => { location.href = hashtag }, TIMEOUT)
    },
    recaptchaVerified(response) {
    },
    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset();
    },
    recaptchaFailed() {
    },
    toggleCollapse(item) {
      item.isVisible = !item.isVisible
      this.articles.forEach((fraction) => {
        if (fraction !== item) {
          fraction.isVisible = false
        }
      })
    },
    hasError(field) {
      return this.errors[field]
    },
    removeError(field) {
      this.errors[field] = false
    },
    validate() {
      let error = false

      if (this.name === "") {
        this.errors.name = error = true
      }

      if (this.email === "") {
        this.errors.email = error = true
      }

      if (this.theme_id === "") {
        this.errors.theme_id = error = true
      }

      if (this.message === "") {
        this.errors.message = error = true
      }

      return ! error
    },
    sendFeedback() {
      if (! this.validate()) {
        return
      }

      const request = {
        name: this.name,
        email: this.email,
        theme_id: this.theme_id,
        message: this.message,
        file_contents: this.file_contents,
      }

      this.$store.dispatch("main/sendFeedback", request).then((response) => {
        if (! response) {
          return
        }

        // this.$notify({
        //   text: "Обращение отправлено!",
        //   type: "success"
        // })
        this.$store.dispatch("bus/notifySuccess", {
          title: "Успешно",
          message: "Обращение отправлено!"
        })

        this.name = '';
        this.email = '';
        this.theme_id = 0;
        this.message = '';
      })
    }
  }
}


</script>

<style lang="scss" scoped>
@import "../assets/scss/variables";

$input-border-color: #DBDBDB;
$input-bg-color: #FFFFFF;
$input-text-color: #666;

.image-preview {
  display: block;
  margin-top: 30px;
  margin-left: 30px;
  max-width: 300px;
}

select {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image:
      linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%);
  background-position:
      calc(100% - 30px) calc(1em + 5px), calc(100% - 25px) calc(1em + 5px);
  background-size:
      5px 5px,
      5px 5px,
      1px 1.5em;
  background-repeat: no-repeat;
}

@media screen and (min-width: 993px) {
  .faq {
    &::before, &::after {
      content: "";
      position: absolute;
      display: block;
      background-size: cover;
    }

    &::before {
      width: 90px;
      height: 100px;
      top: 120px;
      left: 10%;
      /*background-image: url("../assets/svg/label-yellow-thunderbolt.svg");*/
    }

    &::after {
      width: 100px;
      height: 95px;
      top: 120px;
      right: 10%;
      /*background-image: url("../assets/svg/label-heart.svg");*/
    }
  }
}

.input-error {
  border-color: $error-color !important;
}

.faq {
  background-color: #fff;
  border-radius: 40px;
  margin: 20px 8% 20px 8%;
  padding: 40px 30px 40px 30px;
}

.feedback {
  background-color: $orange;
  border-radius: 40px;
  margin: 20px 8% 20px 8%;
  padding: 40px 30px 40px 30px;

  .form {
    background-color: $white;
    width: 75%;
    margin: 0 auto;
    display: block;
    border-radius: 40px;
    padding: 25px 5px 20px 5px;
  }

  input {
    box-sizing: border-box;
    width: 45%;
    margin: 0 2.5% 15px 2.5%;
    height: 60px;
    padding-left: 30px;
    border: 2px solid $input-border-color;
    background-color: $input-bg-color;
    font-family: "Montserrat";
    font-size: 20px;
    border-radius: 50px;
    color: $input-text-color;
    //text-transform: uppercase;
  }

  select {
    box-sizing: border-box;
    width: 95%;
    margin: 0 2.5% 15px 2.5%;
    height: 60px;
    padding-left: 30px;
    border: 2px solid $input-border-color;
    background-color: $input-bg-color;
    font-family: "Montserrat";
    font-size: 20px;
    border-radius: 50px;
    color: $input-text-color;
    //text-transform: uppercase;
  }

  textarea {
    box-sizing: border-box;
    width: 95%;
    margin: 0 2.5% 15px 2.5%;
    height: 200px;
    padding-left: 30px;
    padding-top: 15px;
    border: 2px solid $input-border-color;
    background-color: $input-bg-color;
    font-family: "Montserrat";
    font-size: 20px;
    border-radius: 25px;
    color: $input-text-color;
    //text-transform: uppercase;
  }

  label {
    display: inline-block;
    box-sizing: border-box;
    width: 45%;
    margin: 0 2.5% 30px 2.5%;
    height: 60px;
    border: 2px solid $purple;
    background-color: transparent;
    font-family: "Marvin Round";
    font-size: 20px;
    line-height: 60px;
    text-align: center;
    border-radius: 50px;
    color: $purple;
    text-transform: uppercase;
  }

  label:hover {
    cursor: pointer;
  }

  input[type=file] {
    opacity: 0;
    width: 0;
    height: 0;
  }

  button {
    margin-top: 30px;
  }
}

.collapse {
  &__item {
    border: $purple 2px solid;
    border-radius: 26px;
    padding: 2px;
    margin-bottom: 15px;
  }

  &__item.active {
    background-color: $purple;
  }


  &__item:hover {
    cursor: pointer;
    background-color: $purple;
  }

  &__item:hover &__title {
    background-color: $purple;
    color: $white;
  }

  &__title {
    background-color: #fff;
    color: $purple;
    padding: 15px;
    padding-right: 25px;
    border-radius: 23px;
    position: relative;
  }

  &__body {
    padding: 15px 30px 15px 30px;
    border-bottom-left-radius: 23px;
    border-bottom-right-radius: 23px;
    border-top-right-radius: 23px;
    position: relative;
    display: none;
  }

  &__item.active &__body {
    display: block;
  }

  &__body:before {
    content: "\A";
    border-style: solid;
    border-width: 19px 16px 0px 21px;
    border-color: transparent transparent transparent $orange;
    position: absolute;
    left: 0;
    top: -19px;
  }

  &__item.active &__title {
      background-color: $purple;
      color: #fff;
  }

  &__item.active &__body {
      background-color: $orange;
      color: $black;
  }

  &__item .close {
    width: 15px;
    height: 15px;
    position: absolute;
    z-index: 1;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    transition: all 300ms;
  }

  &__item.active .close {
    transform: translateY(-50%) rotate(45deg);
  }

  &__item .close:before, &__item .close:after {
    content: "";
    position: absolute;
    z-index: -1;
    background: $purple;
  }

  &__item.active .close:before, &__item.active .close:after {
    content: "";
    position: absolute;
    z-index: -1;
    background: $white;
  }

  &__item:hover .close:before, &__item:hover .close:after {
    background: $white;
  }

  &__item .close:before {
    left: 50%;
    width: 2px;
    margin-left: -1px;
    height: 100%;
  }

  &__item .close:after {
    top: 50%;
    height: 2px;
    margin-top: -1px;
    width: 100%;
  }


}

a {
  text-decoration: none;
}

h1, h2, h3 {
  text-align: center;
}

h1 {
  font-size: 60px;
  font-weight: 500;
  color: $green;
}

.wrapper {
  //color: $green;
}

.button {
  width: 376px;
  height: 60px;
  font-family: "Marvin Round";
  font-size: 20px;
  line-height: 60px;
  text-align: center;
  text-transform: uppercase;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  margin: 0 auto;
  display: block;
}

.button-main {
  background-color: $purple;
  color: $white;

  &:hover {
    background-color: $purple;
  }
}

@media screen and (max-width: 992px) {
  .faq {
    padding: 0;
  }

  h1 {
    font-size: 36px;
  }

  .feedback .form {
    background-color: transparent;
    width: 100%;
  }

  .feedback input {
    width: 95%;
  }
}

@media screen and (max-width: 768px) {
  .captcha {
    margin-left: -20px;
  }

  .image-preview {
    width: 100%;
    margin-left: 0;
  }
}
</style>